import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";

// Updated paths for images inside "src/pages/home/components/img/brands/"
const brandData = {
	"just-chicken-wings": {
		title: "Just Chicken Wings",
		description:
			"Tasty & Crispy, our chicken wings and boneless wings are amazing! With a perfect blend of flavors and sauces, they are a customer favorite.",
		image: require("../../pages/home/components/img/brands/brand-1.png"),
	},
	"the-imperial-bamboo": {
		title: "The Imperial Bamboo",
		description:
			"A combination of Chinese roots with an American taste: flavorful and delicious dishes inspired by traditional Chinese cuisine.",
		image: require("../../pages/home/components/img/brands/brand-2.png"),
	},
	"bff": {
		title: "BFF - Burger & French Fries",
		description:
			"Gourmet burgers and fries crafted with a French touch, delivering a unique and unforgettable taste experience.",
		image: require("../../pages/home/components/img/brands/brand-3.png"),
	},
	"crazy-tasty-chicken": {
		title: "Crazy Tasty Chicken",
		description:
			"Perfectly fried chicken designed for gas stations and convenience stores, featuring a secret blend of herbs and spices.",
		image: require("../../pages/home/components/img/brands/brand-4.png"),
	},
	"subs-zone": {
		title: "Sub’s Zone",
		description:
			"Fresh, customizable hoagies made with premium ingredients, offering a variety of breads, meats, and cheeses.",
		image: require("../../pages/home/components/img/brands/brand-5.png"),
	},
	"nypd-pizza": {
		title: "NYPD Pizza",
		description:
			"Authentic New York-style pizza with fresh ingredients, a perfect blend of classic flavors, and hand-tossed crust.",
		image: require("../../pages/home/components/img/brands/brand-6.png"),
	},
	"nypd-pasta": {
		title: "NYPD Pasta",
		description:
			"A variety of hand-crafted pasta dishes inspired by the flavors of New York, using fresh ingredients and traditional sauces.",
		image: require("../../pages/home/components/img/brands/brand-7.png"),
	},
	"luv-que": {
		title: "LUV QUE",
		description:
			"Smoked low and slow. Served hot & fast. Recipes & techniques that deliver consistent, flavor-filled BBQ across the country. On-the-go goodness prepared fresh to order.",
		image: require("../../pages/home/components/img/brands/luv-que.png"), // Ensure this image exists
	},
};

const BrandDetail = ({ brandKey }) => {
	const brand = brandData[brandKey];

	if (!brand) {
		return (
			<div className="container text-center mt-5">
				<h2 className="text-title">Brand Not Found</h2>
				<p>The brand you're looking for does not exist.</p>
				<Link to="/brands" className="cmn-btn">
					<span>Back to Brands</span>
				</Link>
			</div>
		);
	}

	return (
		<section className="section-gap">
			<div className="container">
				<div className="row align-items-center g-4">
					<div className="col-lg-6 text-center">
						<img src={brand.image} alt={brand.title} className="mw-100" />
					</div>
					<div className="col-lg-6">
						<SectionTitle title={brand.title} text={brand.description} shapeColor />
						<div className="mt-4">
							<Link to="/brands" className="cmn-btn">
								<span>Back to Brands</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BrandDetail;

